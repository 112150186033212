import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { StaticQuery, graphql, Link } from 'gatsby'

import '../css/layout.scss'
import '../css/layout2.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <div className={classnames('layout')}>
          <h1><Link to={`/`}>updoor.com</Link></h1>
          <nav className={classnames('global-nav')}>
            <ul>
              <li><Link to={`/`}>top</Link></li>
              <li><Link to={`/blog`}>blog</Link></li>
              <li><Link to={`/me`}>me</Link></li>
            </ul>
          </nav>
          {children}
          <footer>
            © {new Date().getFullYear()}, updoor.com
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
