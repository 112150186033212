import React from 'react'
import Helmet from 'react-helmet';
import classnames from 'classnames';
import Layout from '../components/layout'
import SEO from '../components/seo'

import '../css/me.scss'

export const $me = `export const me = {
  "name": "Shinichirow KAMITO",
  "blood_type": "A",
  "job": "Programmer",
  "languages": [
    "Javascript",
    "Ruby",
    "Python",
    "Erlang",
    "HTML",
    "CSS (Sass)"
  ]
}
`


const MePage = (props) => {
  return (
    <Layout>
      <SEO title="top" />
      <Helmet>
        <title>me</title>
      </Helmet>
      <div className={classnames('me')}>
        <pre>
          <code>{$me}</code>
        </pre>
      </div>
    </Layout>
  )
}

export default MePage
